.account-number-field-wrapper {
  position: relative;

  .account-number-field-info {
    position: absolute;
    right: 0;
    top: 9px;
    background: none;
    color: inherit;
    border: none;
    padding: 8px 12px;
    font: inherit;
    font-size: var(--font-size-xs);
    cursor: pointer;
    outline: inherit;
  }
}
