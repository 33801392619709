.support-page-layout a:hover {
  text-decoration: underline;
}

/* Main Grid Layout */
.support-page-layout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: -20px -10px;
}

.support-page-layout > div {
  margin: 20px 10px;
}

.support-page-layout .contact-us-list {
  grid-column-start: span 3;
}

.support-page-layout .faq-list {
  grid-column-start: span 2;
}

.support-section-title {
  font-size: var(--font-size-l);
  color: #333333;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
  .support-page-layout {
    grid-template-columns: 1fr;
  }
  .support-page-layout .contact-us-list {
    grid-column-start: auto;
  }
  .support-page-layout .faq-list {
    grid-column-start: auto;
  }
}

/* Contact Us Cards Layout */
.contact-us-list .cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: -10px;
}

.contact-us-list .cards.total-cards-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.contact-us-list .cards > div {
  margin: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-us-list .cards,
  .contact-us-list .cards.total-cards-4 {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 767px) {
  .contact-us-list .cards,
  .contact-us-list .cards.total-cards-4 {
    grid-template-columns: 1fr;
  }
}

.contact-us-list .card-title {
  margin-bottom: 3px;
}

.contact-us-list .contact-us-icon {
  display: flex;
  float: right;
  margin-left: 10px;
  padding: 10px;
  background-color: var(--theme-primary);
  border-radius: 20px;
}

.contact-us-list .message-link {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.contact-us-list .chat-card {
  cursor: pointer;
}

.contact-us-list .message-link svg {
  margin-left: 2px;
}

/* Support Card */
.support-page-layout .card {
  position: relative;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 12px rgba(80, 80, 80, 0.05);
  padding: 20px;
}

.support-page-layout .card .card-title {
  font-size: 20px;
  color: #333333;
  font-weight: 600;
  line-height: 28px;
}

/* FAQ Component */
.faq-list .faq-category {
  border-top: 1px solid rgb(var(--border-color));
  padding: 20px 0;
}

.faq-list .faq-category:first-child {
  padding-top: 0;
  border-top: none;
}

.faq-list .faq-category:last-child {
  padding-bottom: 0;
}

.faq-list .faq-category-header {
  display: grid;
  grid-template-columns: 1fr auto;
  cursor: pointer;
}

.faq-list .faq-question-text {
  font-size: var(--font-size-default);
  font-weight: 600;
  line-height: 1.33;
  padding-top: 20px;
  margin-bottom: 8px;
  color: #333333;
}

.faq-list .faq-answer-text {
  font-size: var(--font-size-default);
  line-height: 1.25;
}

/* Quick Links Component */
.quick-link-item {
  margin-bottom: 16px;
  font-weight: 600;
}

.quick-link-item:last-child {
  margin-bottom: 0;
}
