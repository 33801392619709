.beneficiaries-naf-app {
  padding: 4rem;
  min-height: 100%;
  background-color: var(--color-white);
}

.beneficiaries-page-layout {
  display: grid;
  grid-template-columns: minmax(0, 3fr);

  .card-layout {
    grid-column-start: 1;
    position: relative;

    .section-card {
      &:first-of-type {
        margin-top: 1.33rem;
      }
      margin: 0 1.33rem 1.33rem 0;
      position: relative;
      grid-column-start: 1;
    }
  }

  .hover-icon:hover {
    color: RGB(var(--nds-primary-color));
  }

  .bene-table {
    background-color: transparent;
    width: 100%;
    margin-bottom: 0px;
    border-collapse: collapse;

    tbody {
      tr {
        border-top: 1px solid RGB(var(--border-color));
        &:first-of-type {
          border-top: 0px solid RGB(var(--border-color));
        }
        td {
          padding: 12px 12px 12px 0px;
          line-height: 1.25rem;
          font-size: 16px;
          text-align: right;
          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }
}
