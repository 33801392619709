.page-container {
  min-height: 100vh;
  background-color: var(--bgColor-neutralGrey);
}

.progress-buttons-container {
  margin-top: var(--space-xxl);
  width: 100%;
}

.sidebar-page-container {
  display: grid;
  @media (min-width: $desktop-small) {
    grid-template-columns: 336px 1fr;
  }
}

.sidebar-content-container {
  background-color: var(--bgColor-neutralGrey);
  width: 100%;
}

.sidebar-content {
  padding: 60px 31px 80px 31px;
  width: 100%;
  @media (min-width: $desktop-small) {
    padding: 80px 258px 144px 118px;
  }
  div {
    max-width: 666px;
    &.small {
      max-width: 470px;
    }
    &.medium {
      max-width: 568px;
    }
    &.large {
      max-width: 764px;
    }
  }
}

.account-type-container {
  padding: var(--space-l);
  border-radius: 20px;
  background-color: RGBA(var(--theme-rgb-primary), var(--subdued-5-opacity));
  .context-form-field {
    margin-bottom: 0px;
  }
}

.disclosure-links {
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);

  .disclosure-item:not(:first-child) {
    margin-top: var(--space-m);
  }

  a {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-semibold);
    color: RGBA(var(--nds-dark-grey));
    margin-bottom: var(--space-xl);
  }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.question {
  margin: var(--space-l) 0 var(--space-l) 0;
  @media (min-width: $desktop-big) {
    width: 666px;
  }
  @media (min-width: $tablet) {
    width: 666px;
  }
  &:first-of-type {
    margin-top: var(--space-xl);
  }
  &:last-of-type {
    margin-bottom: 0px;
  }
}
.preferences-container {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  margin-top: var(--space-xl);
  .preference-card {
    background-color: var(--bgColor-white);
  }
  .preference-card-divider {
    margin: var(--space-m) 0 var(--space-m) 0;
  }
  .context-form-field:last-child {
    margin-bottom: 0px;
  }
  .confirm-estatements-access {
    margin-bottom: var(--space-l);
  }
}
.title-container {
  margin-bottom: var(--space-xs);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subtext {
  color: var(--color-mediumGrey);
  font-size: var(--font-size-xs);
  line-height: var(--font-lineHeight-default);
}

.last-two-elements {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.toggle-container {
  display: flex;
}
