.hero-banner-container {
  align-items: center;
  margin: 20px 0px 20px 0px;
  padding: 20px;
  background-color: var(--theme-primary);
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  border-radius: 4px;
  color: var(--color-white);

  @media (max-width: $desktop-small) {
    grid-template-columns: none;
    grid-template-rows: 1fr;
    margin-bottom: 0;
  }

  .hero-banner-items {
    max-width: 90vw;
    display: flex;
    flex-direction: row;

    @media (max-width: $desktop-small) {
      flex-direction: column;
    }

    .hero-banner-item {
      padding-right: 60px;
      padding-left: 20px;
      border-left: 1px solid var(--bgColor-neutralGrey);
      display: flex;
      flex: 1;
      flex-direction: column;
      min-width: 0;

      @media (max-width: $desktop-small) {
        border-left: none;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--bgColor-neutralGrey);
      }

      &:last-of-type {
        padding-right: 0;
        @media (max-width: $desktop-small) {
          border-bottom: none;
          padding-left: 0;
          padding-top: 8px;
          padding-bottom: 0;
        }
      }
      &:first-of-type {
        padding-left: 0;
        border-left: none;
        @media (max-width: $desktop-small) {
          max-width: 100%;
        }
      }

      .title {
        font-size: var(--font-size-s);
        font-weight: var(--font-weight-semibold);
        margin-bottom: var(--space-xs);
        color: var(--bgColor-neutralGrey);
      }
      .value {
        font-size: 60px;
        font-weight: var(--font-weight-bold);
        line-height: 60px;
      }
    }
  }

  .hero-banner-button-container {
    flex-grow: 0;
    margin-left: auto;

    @media (max-width: $desktop-small) {
      display: none;
    }

    .hero-banner-button {
      background-color: var(--bgColor-neutralGrey);
      border-radius: 20px;
    }
  }
}
.mobile-hero-banner-button-container {
  background-color: var(--bgColor-neutralGrey);
  margin: 12px 20px 12px 20px;
  @media (min-width: $desktop-small) {
    display: none;
  }
  @include maxViewport("m") {
    button {
      width: 100%;
    }
  }
}

.loan-payoff-dialog-action-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.information-row {
  border-bottom: 1px solid RGBA(var(--nds-lightest-grey));
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
}
