.security-card {
  margin-bottom: var(--space-m);
}

.settings-nav-bar-desktop > .nds-grid {
  @media (max-width: $tablet) {
    display: block;
  }
}

.alerts-title-container {
  margin-bottom: var(--space-s);
  gap: var(--space-s);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage-alerts-text {
  width: 68.75% !important;
  p {
    overflow-wrap: break-word;
  }
}

.active-alerts {
  margin-top: var(--space-m);
  h4 {
    margin-bottom: var(--space-s);
  }
}

.alert-container {
  display: flex;
  justify-content: space-between;
}

.alert-item {
  display: flex;
}

.query-item {
  margin-top: var(--space-xs);
}

.mfa-status {
  display: flex;
}

.devices-container {
  margin-top: var(--space-m);
}

.enabled-devices-header {
  margin-top: var(--space-xl) !important;
  margin-bottom: var(--space-m);
}

.mfa-device-icon {
  font-size: var(--font-size-l);
  margin-right: var(--space-xs);
}

.add-authentication-device-selector-container {
  margin-top: var(--space-xl) !important;
  margin-bottom: var(--space-m);
  .add-mfa-device-footer-buttons {
    margin-top: 0;
  }
}

.device-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-s);
}
