.headerRow {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--color-lightGrey);
}

.headerRow .header {
  font-size: 1rem;
  line-height: 2.5rem;
  margin-right: 2rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
}
.headerRow .header.active {
  font-weight: 600;
  color: var(--theme-primary);
  border-bottom: 3px solid var(--theme-primary);
}

.headerRow .actionBar {
  margin-left: auto;
}
