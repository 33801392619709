.announcement-wrapper {
  display: flex;
  width: 100%;
  min-height: 44px;
  left: 0px;
  top: 0px;
  background: var(--theme-secondary);
  align-items: center;
  padding: 0 18px 0 62px;

  .announcement-text {
    margin: 12px auto 12px auto;
    color: var(--color-white);
    p {
      line-height: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
    }

    a {
      color: var(--color-white);
      text-decoration: underline;
    }
  }

  .announcement-close-button {
    margin-top: 14px;
    margin-left: 32px;
    margin-bottom: auto;
    flex: 0 0 16px;

    svg {
      color: var(--color-white);
      height: 16px;
      width: 16px;
    }
  }
}
