:root {
  --dual-approval-request-table-background-color: 255, 255, 255;
  --notification-bar-background-color: 250, 225, 138;
}

.emptyState {
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 1rem;
}

.DualApprovalRequestList .headerRow {
  border-bottom: none;
}

.dual-approval-request-table.ui.table thead th {
  color: black;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  background: none;
}

.dual-approval-request-table.ui.table thead th:first-child {
  font-size: 16px;
}

.dual-approval-request-table.ui.table tbody td {
  font-size: 16px;
}

.dual-approval-request-table.ui.table {
  border: none;
  background: none;
}

.dual-approval-request-table.ui.table th {
  border-bottom: 1px solid rgb(var(--border-color));
  background-color: rgb(var(--dual-approval-request-table-background-color));
  padding: 16px 16px 8px 16px;
}

.dual-approval-request-table.ui.table td {
  border-bottom: 1px solid rgb(var(--border-color));
  background-color: rgb(var(--dual-approval-request-table-background-color));
  padding: 16px;
}

.dual-approval-request-table.ui.table td:first-child {
  border-left: 1px solid rgb(var(--border-color));
}

.dual-approval-request-table.ui.table td:last-child {
  border-right: 1px solid rgb(var(--border-color));
}

@media only screen and (max-width: 767px) {
  .dual-approval-request-table.ui.table .label {
    font-weight: 600;
  }

  .dual-approval-request-table.ui.table td:first-child {
    font-weight: normal;
  }

  .dual-approval-request-table.ui.table tbody tr:first-child {
    border-top: 1px solid rgb(var(--border-color));
  }

  .dual-approval-request-table.ui.table tbody tr {
    background-color: rgb(var(--dual-approval-request-table-background-color));
    border: 1px solid rgb(var(--border-color));
    border-top: none;
    padding-left: 20px;
  }

  .selectable-rows .dual-approval-request-table.ui.table tbody tr {
    position: relative;
    padding-left: 40px;
  }

  .selectable-rows
    .dual-approval-request-table.ui.table
    tbody
    tr
    td:first-child {
    position: absolute;
    top: 18px;
    left: 5px;
  }
}
