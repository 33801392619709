#login-page-container,
#password-reset-page-container,
#mfa-device-select,
#mfa-code-enter,
#change-password-container {
  display: flex;
  flex-direction: column;
}

.authentication-container {
  width: 500px;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  align-self: center;
}

@media screen and (max-width: 768px) {
  .authentication-container {
    width: 90vw;
  }
}

.authentication-container h1 {
  margin-top: 120px;
  margin-bottom: 12px;
  line-height: 1.1;
  font-size: var(--font-size-heading0);
}

.authentication-container .authentication-help-text {
  width: 75%;
}

.authentication-container .context-form-field {
  margin-top: 20px;
  margin-bottom: 0;
}

.authentication-container .action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.authentication-container .verify-device-options {
  margin-top: 40px;
}

.authentication-container .resend-code-container {
  margin-top: 8px;
}

.authentication-container .code-input-container {
  margin-top: 20px;
}

@media screen and (max-width: 480px) {
  .authentication-container h1 {
    margin-top: 40px;
    font-size: var(--font-size-heading1);
  }
  .authentication-container .mobile-web-extra-space {
    margin-bottom: 20px;
  }
  .authentication-container .action-bar {
    flex-direction: column !important;
  }
  .action-bar button {
    width: 100%;
    margin-bottom: 16px;
  }
  .navBar .get-started button {
    margin-top: 4px;
  }
}

.authentication-container .disabled-text-box {
  pointer-events: none;
  background-color: var(--color-smokeGrey);
  color: var(--color-mediumGrey);
}
