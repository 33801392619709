.transaction_issue_file_upload {
  display: flex;
  margin: var(--space-s);
  margin-top: -5px;
  border-radius: var(--border-radius-s);
  background: var(--bgColor-cloudGrey);
  height: 40px;
  align-items: center;
  justify-content: space-between;
  .progress_bar_container {
    display: flex;
    .progress_bar {
      background: var(--color-white);
      border-radius: var(--border-radius-s);
      border: 1px solid var(--theme-primary);
      width: 100px;
      height: 16px;
      position: relative;
    }
    .progress_fill {
      background: var(--theme-primary);
      height: inherit;
      border-radius: var(--border-radius-s);
      position: absolute;
      top: -1px;
      transition: width 2.5s ease-in;
    }
    .narmi-icon-x {
      margin: 0px 12px 0px 24px;
      cursor: pointer;
    }
  }
}

.report_issue_footer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .report_issue_footer__actions {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: var(--space-xs);
    .context-form-field {
      margin-bottom: 0px;
    }
    .nds-dropdown {
      width: 200px;
      margin-right: var(--space-l);
    }
  }
  .file_upload {
    font-size: 28px;
    margin-right: var(--space-l);
    cursor: pointer;
    color: #8c8c8c;
  }
  .select_wrapper {
    margin-right: var(--space-l);
    width: 110px;
  }
  .average_time_span {
    color: var(--color-mediumGrey);
    margin-left: auto;
    font-size: var(--font-size-s);
  }
  @include minViewport("l") {
    .select_wrapper {
      width: 334px;
    }
  }
}

div.report_issue_modal_text
  > div.context-form-field
  > .nds-input
  .nds-input-box {
  border: none;
  padding-top: 12px;
}

p.transaction_details {
  &:first-line {
    font-weight: bold;
  }
  padding: var(--space-xs) var(--space-s) var(--space-l) var(--space-s);
  padding-top: 4px;
  padding-bottom: 12px;
  font-size: 12px;
  white-space: pre-wrap;
  color: var(--color-mediumGrey);
}
