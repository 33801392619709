.banking-address-grid {
  display: grid;
  flex-direction: row;
  gap: var(--space-l) var(--space-m);

  @media (min-width: $tablet) {
    grid-template-columns: 3fr 176px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: $tablet) {
  .nds-dialog .banking-address-grid {
    grid-template-columns: 3fr 129px;
  }
}
