.profile-setting-row {
  display: flex;
  justify-content: space-between;
  padding: var(--space-m) 0;
  align-items: center;

  .profile-setting-field {
    display: flex;
    flex-direction: column;

    .profile-setting-label {
      color: var(--color-mediumGrey);
      line-height: 20px;
      margin-bottom: var(--space-xxs);
    }
  }

  &:last-child {
    padding-bottom: 0;
  }
}
