.documents-page {
  margin-top: var(--space-m);
  text-align: left;
  flex-wrap: nowrap;
  gap: var(--space-s);
  @media (max-width: $tablet) {
    flex-wrap: wrap;
  }

  .filter-list {
    flex-wrap: wrap;
    gap: var(--space-s);
  }

  .document-list {
    min-width: 72.5%;
    @media (max-width: $tablet) {
      width: 100%;
    }

    .document-card {
      padding: var(--space-l);
      border-radius: var(--border-radius-s);
      background-color: var(--color-white);
      border: var(--border-size-s) solid var(--bgColor-cloudGrey);
      margin-bottom: var(--space-xl);
      text-align: left;
      width: 525px;
      @media (max-width: $tablet) {
        width: unset;
      }
      .document-title {
        font-weight: var(--font-weight-bold);
        margin-bottom: var(--space-s);
      }
    }
  }
}

.filter-button {
  padding: var(--space-xxs) var(--space-xs);
  border: 1px solid var(--bgColor-cloudGrey);
  display: inline-flex;
  border-radius: var(--border-radius-l);
  font-size: var(--font-size-xs);
}
