@import "../../../scss/mixins";

.section-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: var(--theme-primary);
}

a:hover.section-title {
  color: var(--theme-primary);
}

.section-border {
  margin-bottom: 20px;
  border-bottom: 2px solid RGB(var(--border-color));
  padding-bottom: 4px;
}

.section-box:nth-child(1) {
  margin: 0;

  @include minViewport("l") {
    margin-top: 20px;
  }
}

.section-box,
.context-form-field .section-box {
  margin: 40px 0px 0px;
}

.nds-input-style-override {
  input {
    line-height: normal;
    text-rendering: geometricPrecision;

    &::placeholder {
      color: RGB(var(--input-grey-color)) !important;
      font-weight: 400;
    }
  }
}

.searchAndFilterContainer {
  display: flex;
  align-items: center;

  > .mobile-only {
    height: 48px;
    width: 44px;
  }

  @include minViewport("l") {
    margin-top: var(--space-l);
  }
}

.filterTitle {
  color: RGB(var(--title-color));
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.filterSearchPopover {
  display: flex;
  justify-content: flex-end;

  > div {
    width: 188px;
  }
  .nds-input-box {
    padding-right: 0 !important;
  }
}

.filterSectionTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--space-s);
  margin-top: var(--space-l);

  &:first-of-type {
    margin-top: 0;
  }
}

.filterTextAndIcon {
  display: flex;
  justify-content: space-between;
  > span {
    color: RGB(var(--nds-medium-grey));
  }
}

.searchAndFilterContainer {
  align-items: center;
  display: flex;

  > * {
    margin: 0 4px;
    width: 188px;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0 !important;
  }
  .cancelEditSearch {
    width: auto;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  > .inputBox {
    padding: 8px 12px;
    border: 1px solid RGB(var(--border-color));
    box-sizing: border-box;
    border-radius: 4px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    background: var(--color-white);

    input::placeholder {
      color: RGB(var(--input-grey-color)) !important;
    }
    &.nds-input-style-override {
      padding: 0px;
      border: 0px;
    }
    &.search {
      flex-grow: 1;
      input {
        color: RGB(var(--input-grey-color));
      }
    }
    &.editSearch {
      width: 44px;
      cursor: pointer;
      color: RGB(var(--input-grey-color));
    }
    &.filter {
      border: none;
      box-shadow: 0px 2px 12px RGBA(80, 80, 80, 0.2);
      width: 150px;
      padding: 12px;

      input[type="checkbox"] {
        -moz-appearance: none;
        -webkit-appearance: none;
        -o-appearance: none;
        outline: none;
        content: none;

        &:before {
          font-family: Icons;
          content: "\f00c";
          font-size: 14px;
          color: transparent !important;
          width: 15px;
          height: 15px;
          padding: 2px;
          border: 1px solid RGB(var(--border-color));
          border-radius: 3px;
          cursor: pointer;
        }
        &:hover:before {
          border: 1px solid var(--theme-primary);
        }
        &:checked:before {
          color: var(--color-white) !important;
          background-color: var(--theme-primary);
          border: 2px solid var(--theme-primary);
        }
      }
    }

    > .filterText {
      font-size: 16px;
      color: RGB(var(--input-grey-color));
    }
  }
}

.inputBox {
  &.search {
    input[type="text"] {
      font-weight: 400;
      padding: 2px 0;
      color: RGBA(var(--text-color));
    }
    .nds-input-icon.narmi-icon-search {
      font-size: 1em;
    }
  }
}

.mobileListContents {
  padding: 20px 0;
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid RGB(var(--border-color));

  .titleLine {
    display: flex;
  }
  .name {
    font-weight: 700;
    font-size: 16px;
    flex-grow: 1;
  }
  .tag {
    font-weight: 700;
    font-size: 14px;
  }
}

.ui.table.OrganizationUsersTable {
  background-color: transparent;
  border: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

  .flex {
    display: flex;
    align-items: center;
  }
  thead th {
    padding: 20px 20px 8px;
    border: none;
    font-weight: 700;
    color: RGB(var(--title-color));
    font-size: 12px;
    line-height: 1rem;
    font-family: var(--nds-font-family);
  }
  tbody {
    background-color: var(--color-white);
    border-radius: 4px;
    box-shadow: var(--elevation-low);

    td {
      padding: 20px;
      font-size: 16px;
      line-height: 1.25rem;
    }
    tr {
      box-shadow: 0px 2px 12px 0px
        RGBA(var(--box-shadow-color), RGB(var(--box-shadow-opacity)));

      &:first-child {
        border-top: none;

        td:first-child {
          border-top-left-radius: 4px;
        }
        td:last-child {
          border-top-right-radius: 4px;
        }
      }
      &:last-child {
        td:first-child {
          border-bottom-left-radius: 4px;
        }
        td:last-child {
          border-bottom-right-radius: 4px;
        }
      }
      &:hover td {
        background: RGBA(var(--theme-rgb-primary), var(--hover-opacity));
      }
    }
  }
}

.inverted-primary-color-checkbox {
  padding: 16px 0 16px;
  margin: 0 20px;

  .external-account-checklist {
    padding-top: 12px !important;
    padding-bottom: 0px !important;
    margin: 0px 28px !important;
    border: 0px !important;

    &:last-of-type {
      margin-bottom: 16px !important;
    }
  }

  .user-limits {
    width: 225px;
    border-top: 0px !important;
    margin: var(--space-m) 0px 0px 28px;

    .nds-input label {
      border-top: 0px !important;
    }
  }
  &:first-child {
    padding: 20px 0 16px;
  }
  &:last-child {
    padding: 16px 0 20px;
  }
}

.access-manager-limits {
  .subuser-limits {
    &:first-of-type {
      margin-top: 0 !important;
    }
    margin-top: var(--space-xs);
    font-size: var(--font-size-s);
  }
}

.permission-check-list .inverted-primary-color-checkbox:not(:first-child) {
  border-top: 1px solid RGBA(var(--border-color));

  &.nds-input-error {
    border-top: none;
  }
}

.title-style {
  margin: var(--space-l) 0px 0px var(--space-l);
  text-transform: uppercase;
}

.account-activity-title-style {
  margin-top: var(--space-xl) !important;
}

/* tooltip styling */
.tooltip-info-icon {
  position: absolute;
  top: 24px;
  right: 16px;
  transform: translateY(-44%);
}

.has-tooltip-child {
  position: relative;
}

/* for hiding mobile stuff */
.mobile-hidden {
  display: none !important;
  @media only screen and (min-width: 768px) {
    display: revert !important;
  }
}

.mobile-only {
  @media only screen and (min-width: 768px) {
    display: none !important;
  }
}

.access-manager-sorting {
  padding: var(--space-xxs) 0 var(--space-xxs) 0;

  .dropdown-list-item {
    padding: 6px 11px 6px 8px;
    font-size: var(--font-size-s);

    .pre-option-checkmark {
      width: max-content;
    }
    .dropdown-list-item-content {
      width: max-content;
    }
  }
}
