.service-page-container {
  padding-top: var(--space-s);
  padding-left: var(--space-l);
  padding-right: var(--space-l);
  display: flex;
  justify-content: center;
  width: 100%;

  .nds-tabs {
    width: 100%;
  }
  .full {
    padding-top: 0 !important;
    overflow-y: hidden;
  }
}
