.automatic-savings-page {
  flex-wrap: nowrap;
  max-width: 1198px;
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--space-l);

  [class*="column"] {
    padding: 0 var(--space-xl);
  }

  .left-column {
    width: 37.5%;
  }

  .right-column {
    width: 62.5%;
  }

  @media (max-width: 992px) {
    flex-wrap: wrap;

    [class*="column"] {
      width: 100%;
      max-width: 800px;
    }
  }
}

.savings-rules-card-wrapper {
  background-color: var(--color-white);
  border: 1px solid var(--bgColor-cloudGrey);
  border-radius: var(--border-radius-s);
  margin-bottom: var(--space-xl);
  padding: var(--space-l);
  max-width: 380px;

  [data-classname="AppAuthorize open-an-account-button"] {
    margin-left: 0;

    > .nds-button-content {
      margin: 0;

      // higher specificity version of an NDS rule
      .nds-button--primary > .nds-button-content {
        margin: var(--space-xs) 32px;
      }
    }
  }

  h4 {
    padding-bottom: var(--space-m);
  }
}

.amount-saved-card-wrapper {
  background: var(--theme-tertiary);
  color: var(--color-white);
  border-radius: var(--border-radius-s);
  margin-bottom: var(--space-xl);
  padding: var(--space-l);
  text-align: center;

  h1,
  h4 {
    color: var(--color-white);
    font-family: var(--font-family-body);
    line-height: 1.25;
  }
}

.recent-activity-card-wrapper {
  background-color: var(--color-white);
  border: 1px solid var(--bgColor-cloudGrey);
  border-radius: var(--border-radius-s);
  padding: var(--space-l) 0 0;

  h2 {
    font-family: var(--font-family-body);
    margin-bottom: var(--space-xl);
  }

  .activity-row {
    margin: var(--space-m) 0;

    [class*="cell"] {
      padding: 0 var(--space-m);

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }

      // at smaller screen size, the last cell in the row (amount) disappears (through display: none) but it's still in the DOM
      // this styling rule removes the right-padding for the 2nd-to-last cell (aka the last visible column)
      @media (max-width: $tablet) {
        &:nth-last-child(2) {
          padding-right: 0;
        }
      }
    }

    .date-cell {
      width: 12.5%;
      flex-direction: column;

      .creation-day {
        font-size: var(--font-size-heading3);
        font-weight: var(--font-weight-semibold);
      }

      @media (max-width: $tablet) {
        padding: 0 var(--space-xs);
        width: 20%;
      }
    }

    .description-cell {
      width: 75%;
      overflow-wrap: break-word;

      @media (max-width: $tablet) {
        padding: 0 var(--space-xs);
        width: 80%;
      }
    }

    .amount-cell {
      width: 12.5%;
      color: var(--theme-primary);

      // hide the amount cell at smaller screen size since the amount information is also in the description cell
      @media (max-width: $tablet) {
        display: none;
      }
    }
  }

  .no-activity-state {
    flex-direction: column;
    margin-bottom: var(--space-xl);

    img {
      width: 150px;
      margin-bottom: var(--space-m);
    }

    .no-activity-description {
      text-align: center;
      color: var(--font-color-secondary);
      margin: var(--space-l) 0;
    }
  }

  .view-account-button {
    border-top: 1px solid var(--bgColor-cloudGrey);
    font-weight: var(--font-weight-semibold);
    color: var(--theme-primary);
    padding: var(--space-l);

    .narmi-icon-chevron-right {
      font-weight: var(--font-weight-semibold);
    }

    &:not([role="button"]) {
      color: var(--font-color-hint);
    }

    &[role="button"]:hover {
      background-color: var(--theme-primary);
      color: var(--color-white);
    }
  }
}

[data-testid="edit-rule-dialog"] .nds-dialog-content {
  overflow-y: visible;
}
