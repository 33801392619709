.account-selector-list-item {
  white-space: nowrap;
  padding: 10px 0px 10px 11px;
  margin: 0;
}

.account-selector-list-item-hoverable {
  white-space: nowrap;
  padding: 10px 0px 10px 11px;
  margin: 0;
}

.account-selector-list-item-hoverable:hover {
  background-color: RGBA(var(--theme-rgb-primary), var(--hover-opacity));
}

.dropdown-list-item.hoverable:hover {
  background-color: RGBA(var(--theme-rgb-primary), var(--hover-opacity));
}

.dropdown-search-text-input {
  height: 40px;
}

.dropdown-list-item {
  white-space: pre-wrap;
  padding: 6px 25px 6px 11px;
  font-size: 16px;
  &:first-of-type:last-of-type {
    padding: 14px 25px 10px 11px;
    min-height: 24px;
  }
}

.recipient-screen__recipient-selector {
  .dropdown-list-item {
    padding: 10px 12px;
  }
  .dropdown-list-item-content {
    width: 100%;
  }
}

// adds padding to truncate dropdown input
.dropdown-container .nds-input-column {
  padding: 0px 12px 0px 0px;
}

.dropdown-search .nds-input-box {
  border: none;
  border-bottom: 1px solid RGBA(var(--nds-lightest-grey));
  border-radius: 0px;
  border-top-left-radius: 12px;
  min-height: 40px;
}

.dropdown-search .nds-input-box:focus-within {
  border: none;
  border-bottom: 1px solid RGBA(var(--nds-lightest-grey));
  border-radius: 0;
}

.dropdown-search .nds-input-box input {
  cursor: text;
  color: var(--color-mediumGrey);
}

.dropdown-container {
  position: relative;
  cursor: pointer;
}

.dropdown-list-item {
  color: var(--color-black);
}

.dropdown-list-item.hoverable.clickable {
  color: var(--color-grey);
}

//styles to indicate truncated text
.dropdown-container input {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

$checkmark_width: 36px;

.pre-option-checkmark {
  width: $checkmark_width;
}

.dropdown-list-item-content {
  width: calc(100% - #{$checkmark_width});
}
