@import "../../scss/mixins";

.nds-card {
  &:first-of-type {
    margin-top: 0;
    &.balances-card {
      margin-top: 0;

      @include minViewport("l") {
        margin-top: 1.33rem;
      }
    }
  }
  &.borderless {
    border: none;
    height: fit-content;
    margin-top: 1.33rem;
  }

  &.balances-card {
    margin-bottom: 1.33rem;
    border: none;
    height: fit-content;
    text-align: center;
    background-color: RGBA(var(--theme-rgb-primary), var(--ephemeral-opacity));
    padding: 16px;

    .available-balance {
      font-weight: bold;
      color: var(--theme-primary);
    }

    .available-balance-title {
      margin-right: var(--space-xxs);
      color: RGBA(var(--theme-rgb-primary), 0.8);
      font-weight: var(--font-weight-semibold);
    }

    .narmi-icon-info {
      color: RGBA(var(--theme-rgb-primary), 0.8);
      transform: scale((12/14));
    }
  }
}
