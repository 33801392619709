@import "../../../../../../../scss/mixins";

.wire-via-template-container {
  display: flex;
  flex-direction: column;
  padding: var(--space-xs) var(--space-l);
  .mobile-web-extra-space {
    max-width: 568px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}

@include minViewport("m") {
  .wire-via-template-container {
    padding-top: var(--space-xs);
  }
}

@include minViewport("l") {
  .wire-via-template-container {
    padding-top: var(--space-xs);
  }
}