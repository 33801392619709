@import "../../../scss/mixins";

.sudo-mode-container {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-xl);
  margin: 0 var(--space-l);
  .mobile-web-extra-space {
    max-width: 470px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}