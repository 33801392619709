.context-form-field {
  margin-bottom: 20px;
}

.form-card-layout {
  width: 568px;
}

@media screen and (max-width: 768px) {
  .form-card-layout {
    width: 90vw;
  }
}

.form-card-layout .card {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  padding: 40px;
}

.long-form {
  position: relative;
  form {
    // override user agent stylesheet
    margin-block-end: 0;
  }
}

.sticky-submit-area {
  background-color: var(--color-white);
  padding-top: var(--space-xl);
  position: sticky;
  bottom: calc(-1 * var(--space-xl));
  left: 0;
  right: 0;
}
