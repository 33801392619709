.password-requirements-container {
  margin-top: var(--space-l);

  .password-requirements-title {
    font-size: var(--font-size-s);
    color: var(--font-color-secondary);
  }

  .password-requirements {
    margin: 0;
    padding-left: var(--space-l);
    font-size: var(--font-size-s);
    color: var(--font-color-secondary);
    padding-bottom: var(--space-xl);

    li {
      line-height: var(--font-lineHeight-smallText);
    }
  }
}
