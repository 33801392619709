.transaction-dialog {
  .amount {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-l);
    line-height: var(--font-lineHeight-bodyText);
    margin-top: -4px; // spacing between amount and the title should add up to 8px
    margin-bottom: var(--space-s);
    &.positive {
      color: var(--color-successDark);
    }
    &.negative {
      color: var(--color-errorDark);
    }
  }

  .map {
    height: 240px;
    margin-bottom: var(--space-s);
  }

  .transaction-attributes {
    margin-bottom: var(--space-l);

    .transaction-row-wrapper {
      &:first-of-type {
        border-top: none;
      }

      border-top: var(--border-size-s) solid var(--color-lightGrey);
      padding-bottom: var(--space-xs);
      padding-top: var(--space-xs);
    }
  }
}

.attribute-label {
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semibold);
  color: var(--font-color-heading);
}

.attribute-content {
  text-align: right;
  &::first-letter {
    text-transform: capitalize;
  }
}

.inverted-color-plus-circle-container,
.inverted-color-minus-circle-container {
  position: relative;
  display: flex;
  height: 16px;
  width: 16px;

  > .narmi-icon-solid-circle {
    position: absolute;
    color: var(--color-mediumGrey);
  }

  > .plus-sign-wrapper,
  > .minus-sign-wrapper {
    height: 12px;
    width: 12px;
    margin: auto;

    > .narmi-icon-plus,
    > .narmi-icon-minus {
      position: absolute;
      color: var(--color-white);
      font-size: 12px;
    }
  }
}

.search-and-filter-border {
  border-top: var(--border-size-s) solid var(--color-lightGrey);
  padding-top: var(--space-l);
}

.requests-card-subsection-title {
  color: var(--theme-primary);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-m);
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
}

.requests-card-subsection-item {
  border-top: var(--border-size-s) solid var(--border-color-light);
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
  &:last-of-type {
    padding-bottom: 0px;
  }
}

.tags-row {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-s);
  margin: var(--space-s) 0;
  &.search {
    margin-bottom: 0;
  }
}

@media (min-width: $desktop-small) {
  .transaction-row .row-item {
    &:first-child {
      padding-left: var(--space-xxs);
    }
    &:last-child {
      padding-right: var(--space-xxs);
    }
  }
}

.row-item.account-name {
  @media (min-width: $desktop-small) {
    color: var(--font-color-primary);
    font-size: var(--font-size-default);
  }

  color: var(--theme-primary);
  font-size: var(--font-size-s);
}

.responsive-text-styling {
  @media (min-width: $desktop-small) {
    color: var(--font-color-primary);
    font-size: var(--font-size-default);
  }

  color: var(--color-mediumGrey);
  font-size: var(--font-size-s);
}

.transaction-row:hover {
  background-color: RGBA(var(--theme-rgb-primary), var(--alpha-5));

  @media (min-width: $desktop-small) {
    /* .transaction-row (which also usually has a .row-wrapper class) gets `display: contents`
      set at this viewport, which means background-color stops working.

      the solution is to make sure its descendants (which have proper display) sets the background-color
    */
    background-color: unset;

    .row-item {
      background-color: RGBA(var(--theme-rgb-primary), var(--alpha-5));
    }
  }
}

.pending-wires-info-icon {
  @media (min-width: $desktop-small) {
    display: none;
  }
  display: inline-flex;
  transform: scale((10/14));
}
