:root {
  --message-self-color: rgba(42, 68, 148, 1);
  --message-support-color: rgba(42, 68, 148, 0.1);
}

.message-center-wrapper-page .section-header-background {
  margin-bottom: 20px;
}

div.message-center-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -20px;
}

div.message-center-header .ui.button.new-thread-button {
  margin: 0;
}

/* Main Grid Layout */
.message-center-layout {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-top: 20px;
  background-color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .message-center-layout {
    grid-template-columns: 1fr;
  }
}

/* Tabbed Thread List Layout */
.tabbed-thread-list {
  display: flex;
  min-width: 360px;
  flex-direction: column;
  padding-bottom: 20px;
}
@media (min-width: 767px) {
  .tabbed-thread-list {
    width: 416px;
  }
}

.tabbed-thread-list .headerRow {
  height: 55px;
  padding: 20px 20px 0;
}

.tabbed-thread-list .headerRow .header {
  flex-shrink: 0;
  line-height: unset;
}

.tabbed-thread-list .thread-list {
  max-height: 725px;
}

@media only screen and (max-width: 767px) {
  .tabbed-thread-list .thread-list {
    max-height: 425px;
  }
}

.ui.button.new-thread-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 40px 0;
}

.ui.button.new-thread-button svg {
  margin-right: 5px;
}

/* Thread List Component */
.thread-list {
  padding: 20px 20px;
  flex-grow: 1;
  overflow-y: auto;
}

.thread-item {
  min-width: 250px;
  padding: 16px 24px;
  color: rgb(var(--text-color));
  position: relative;
}

.thread-item.selected:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--theme-primary);
  opacity: 0.1;
  border-radius: 4px;
}

.thread-item-title {
  font-weight: 600;
  color: black;
}

.thread-read-indicator {
  position: absolute;
  left: 8px;
  margin-top: -2px;
}

.thread-read-indicator-icon {
  color: var(--theme-primary);
  font-size: 10px;
}

/* Search Component */

.search-bar {
  display: flex;
  padding: 20px 20px 0;
}

.search-bar .ui.input {
  flex-grow: 1;
}

.search-bar input {
  padding-left: 36px !important;
}

.ui.button.message-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 0;
  margin-right: 10px;
}

.ui.button.message-icon-button:hover {
  border: none;
}

.message-search-icon {
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  height: 100%;
}

/* Messages Layout */
.thread-message-list {
  border-left: 1px solid rgb(var(--border-color));
  display: flex;
  flex-direction: column;
  height: 800px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .thread-message-list {
    height: unset;
    max-height: 800px;
  }
}

.thread-message-list .thread-subject {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(var(--border-color));
  font-size: 20px;
  font-weight: 600;
  height: 55px;
  flex-shrink: 0;
  flex-grow: 0;
}

.thread-message-list .message-list-scroller {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}

.thread-message-list .message-list {
  display: flex;
  flex-direction: column-reverse;
  padding: 0 40px;
}

/* Conversation Layout */
.message-list .message-item {
  max-width: 480px;
  width: 90%;
  margin-bottom: 20px;
}

.message-list .message-item:first-child {
  margin-bottom: 0;
}

.message-list .message-item .message-author {
  font-size: 14px;
  font-weight: 400;
  color: rgb(var(--input-grey-color));
}

.message-list .message-item .message-datetime {
  font-size: 14px;
  font-weight: 400;
  color: rgb(var(--input-grey-color));
}

.message-list .message-item .message-body {
  padding: 20px;
  border-radius: 4px;
  overflow: hidden;
}

.file-message {
  display: flex;
  align-items: center;
}

.file-message a {
  margin-left: 0.5em;
  color: inherit;
}

.file-message a:hover {
  margin-left: 0.5em;
  color: inherit;
  text-decoration: underline;
}

.date-boundary:last-child {
  padding-top: 20px;
}

.date-boundary {
  display: flex;
  align-items: center;
  color: #8c8c8c;
  margin-bottom: 20px;
}

.date-boundary .date {
  flex-shrink: 0;
  flex-grow: 0;
}

.date-boundary::before {
  display: block;
  content: "";
  height: 1px;
  background-color: #d9d9d9;
  flex-grow: 1;
  margin-right: 8px;
}

.date-boundary::after {
  display: block;
  content: "";
  height: 1px;
  background-color: #d9d9d9;
  flex-grow: 1;
  margin-left: 8px;
}

.message-list .message-item.self {
  text-align: right;
  align-self: flex-end;
}

.message-list .message-item .message-body {
  background-color: RGBA(var(--theme-rgb-primary), var(--alpha-10));
}

.message-list .message-item .message-body * {
  z-index: 2;
}

.message-list .message-item.self .message-body {
  text-align: left;
  color: white;
  background-color: var(--theme-primary);
}

/* Thread Reply Layout */

.thread-reply {
  padding: 20px 40px 0;
  text-align: right;
  flex-shrink: 0;
  flex-grow: 0;
}

.thread-reply.ui.form textarea {
  resize: none;
  height: 145px;
}

.ui.button.message-search-button {
  background: white;
  border: none;
  padding-left: 8px;
  padding-right: 0px;
  color: #8c8c8c;
}
.ui.button.message-search-button:hover {
  color: #4c4c4c;
}

.ui.modal > .new-thread-content {
  padding: 40px !important;
}

.new-thread-content .ui.form textarea {
  resize: none;
  height: 145px;
}

/* New Thread Layout */
.new-thread-attachment {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: rgb(var(--border-color));
  padding: 0.5em 0.75em;
}

.new-thread-attachment > div:first-child {
  flex-grow: 1;
}

.new-thread-attachment svg {
  cursor: pointer;
}

.thread-message-list .actionbar > div.error-message {
  display: none;
}

.new-thread-content .actionbar > div.error-message {
  display: none;
}

.new-thread-content .field {
  margin-bottom: 8px !important;
}

.new-thread-modal-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 20px;
}

.new-thread-expected-reply-time {
  color: #8c8c8c;
  font-size: 14px;
  margin-top: 10px;
}

.thread-reply-expected-reply-time {
  font-size: 14px;
  margin-top: 8px;
  color: #8c8c8c;
}
