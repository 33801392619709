@import "../../../scss/functions";

#footer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--bgColor-neutralGrey);

  .grid-footer {
    flex-grow: 1;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .footer-container {
    border-top: 1px solid var(--color-lightGrey);
    height: 355px;
    @media (max-width: $tablet) {
      height: 600px;
    }

    > div {
      margin-top: 60px;
      @media (max-width: $tablet) {
        margin-top: 40px;
      }
    }

    .background-ao {
      background-color: var(--bgColor-white);
    }

    .background-db {
      background-color: var(--bgColor-neutralGrey);
    }

    .medium-grey {
      color: RGBA(var(--nds-medium-grey));
    }

    .footer-copyright {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: $tablet) {
        p {
          margin-bottom: 4px;
        }
      }
    }

    .copyright-image {
      margin-left: 8px;
    }

    .footer-logo {
      max-width: 180px;
    }

    .footer-ehl-logo {
      height: 24px;
      width: 24px;
    }

    .footer-rate-definitions {
      margin-top: 36px;
      @media (max-width: $tablet) {
        margin-top: 12px;
      }
    }

    .fi-information {
      min-width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      @media (max-width: $tablet) {
        margin-top: 20px;
        flex-direction: column;
        row-gap: 20px;
      }
    }

    .fi-information-column {
      margin-right: 40px;
      @media (max-width: $tablet) {
        p {
          margin-bottom: 8px;
        }
      }
    }
    .social-media-icons {
      margin-top: 40px;
      margin-bottom: 40px;
      @media (max-width: $tablet) {
        margin-top: 20px;
      }

      .social-media-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #8c8c8c;
        height: rem(25px);
        width: rem(25px);
        border-radius: 50%;
        margin-right: var(--font-size-default);

        .socialIcon::before {
          color: white;
        }

        a:hover {
          text-decoration: none;
        }
      }
    }

    a:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    .terms-and-policy {
      margin-top: 2rem;
      color: var(--theme-secondary);
    }

    .grey-link {
      color: RGBA(var(--nds-grey));
    }

    .grey-link:hover {
      color: RGBA(var(--nds-grey));
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
