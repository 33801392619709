#external-account-manager {
  width: 100%;
}

.external-account-card-list {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-top: 20px;
}

@media (min-width: 1280px) {
  .external-account-card-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 20px 0px 20px 0px;
  }
}

.external-account-content-wrapper {
  margin-bottom: 20px;
  .italic {
    font-style: Italic;
  }
}

@media (min-width: 1280px) {
  .external-account-content-wrapper {
    width: 568px;
    margin-top: 0px;
    margin-right: 20px;
    box-shadow: 0px 2px 12px 0px #5050500d;
  }
}

.externalAccount-options-popover {
  padding: var(--space-xxs) 0;

  div:hover {
    background: RGBA(var(--theme-rgb-primary), var(--hover-opacity));
    cursor: pointer;
  }
}

.verify-account-button {
  color: var(--theme-primary);
  &:hover {
    cursor: pointer;
  }
}

.generic-bank-logo {
  background-color: rgba(var(--theme-rgb-primary), var(--alpha-10));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  flex: 0 0 40px;

  .narmi-icon-bank {
    font-size: var(--font-size-l);
    color: var(--theme-primary);
  }
}

.external-account-more-menu {
  $button-inner-padding: var(--space-l);
  $content-card-padding: var(--space-l);
  $icon-top-margin: 10px;
  $icon-right-margin: 4px;
  height: 10px;
  display: flex;

  margin-top: calc(
    $button-inner-padding - $content-card-padding - $icon-top-margin
  );
  margin-right: calc(
    $button-inner-padding - $content-card-padding - $icon-right-margin
  );
}

.external-account-more-menu:hover {
  border-radius: var(--border-radius-s);
  background-color: RGBA(var(--theme-rgb-primary), var(--ephemeral-opacity));
}
