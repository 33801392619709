.recipients-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 274px);
  gap: var(--space-l);

  .delete-member-icon {
    $button-inner-padding: var(--space-s);
    $content-card-padding: var(--space-l);
    $icon-top-margin: 6px;
    $margin-from-corner: calc(
      $button-inner-padding - $content-card-padding - $icon-top-margin
    );

    margin-top: $margin-from-corner;
    margin-right: $margin-from-corner;
  }

  .more-options {
    height: 10px;
    display: flex;

    &:hover {
      border-radius: var(--border-radius-s);
      background-color: RGBA(
        var(--theme-rgb-primary),
        var(--ephemeral-opacity)
      );
    }
  }
}

.more-options-popover {
  padding: var(--space-xxs) 0;
  [role="button"]:hover {
    background: RGBA(var(--theme-rgb-primary), var(--hover-opacity));
    cursor: pointer;
  }
}

.recipient-more-menu {
  $button-inner-padding: var(--space-l);
  $content-card-padding: var(--space-l);
  $icon-top-margin: 10px;
  $icon-right-margin: 4px;
  height: 10px;
  display: flex;

  margin-top: calc(
    $button-inner-padding - $content-card-padding - $icon-top-margin
  );
  margin-right: calc(
    $button-inner-padding - $content-card-padding - $icon-right-margin
  );
}

.recipient-more-menu:hover {
  border-radius: var(--border-radius-s);
  background-color: RGBA(var(--theme-rgb-primary), var(--ephemeral-opacity));
}

.recipient-section-header {
  display: flex;
  flex-direction: row;
  border-radius: var(--border-radius-s);
  justify-content: space-between;
}

.recipient-popover {
  margin-top: -4px;
}

.recipient-popover-options {
  padding: var(--space-xxs) 0;

  div:hover {
    background: RGBA(var(--theme-rgb-primary), var(--hover-opacity));
    cursor: pointer;
  }
}
