.refer-a-member-page-layout {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.refer-a-member-page-layout-header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: RGBA(var(--theme-rgb-primary), var(--alpha-20));
  padding-bottom: 7%;
  padding-top: 7%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  max-height: 543px;
}

.refer-a-member-title {
  left: 0px;
  top: 0px;

  font-family: var(--nds-header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  /* Text/Black */
  color: RGBA(var(--nds-black));

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  padding-bottom: 12px;
}
.refer-a-member-subtitle {
  width: 600px;
  height: auto;
  left: 47px;
  top: 60px;

  font-family: var(--nds-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;

  align-items: center;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 12px;
}

.number-layout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: -10px;
  justify-content: center;
  text-align: center;
  padding-top: 5%;
  padding-left: 20%;
  padding-right: 20%;
  position: relative;
  max-width: 500x;
  min-height: 0;
  min-width: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .number-layout {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 767px) {
  .number-layout {
    grid-template-columns: 1fr;
  }
}
.number-layout .number {
  padding: 20px;
  text-align: center;
  margin: 0 auto;
  min-width: 0;
}

.number .titletext {
  font-family: var(--nds-header-font);
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 60px;
  text-align: center;
  /* identical to box height, or 125% */
  align-items: center;
  letter-spacing: 0.5px;

  /* Text/Black */
  color: RGBA(var(--nds-black));
}

.number .subtext {
  font-family: var(--nds-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  width: 253px;
  height: 40px;
}

.number .circle {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  padding: 7px;
  background-color: var(--theme-primary);
  color: RGBA(var(--nds-white));
  text-align: center;
  font: 48px var(--nds-font-family);
  margin: 0 auto;
}
