@use "sass:map";
@import "./vars";

/**
* Wraps content in a media query that targets the
* given breakpoint size _and larger_.
*
* ```scss
* @include minViewport("m") {
*   // styles targeting "m" size _and larger_
* }
* ```
*/
@mixin minViewport($breakpointSize) {
  $breakpoint: map-get($breakpoints-map, $breakpointSize);
  @media only screen and (min-width: #{$breakpoint}) {
    @content;
  }
}

/**
* Wraps content in a media query that targets viewports
* smaller than the given breakpoint size.
* (Not inclusive of the given breakpoint)
*
* ```scss
* @include maxViewport("m") {
*   // styles targeting viewports smaller than "m"
* }
* ```
*/
@mixin maxViewport($breakpointSize) {
  $breakpoint: map-get($breakpoints-map, $breakpointSize);
  @media only screen and (max-width: #{$breakpoint - 1px}) {
    @content;
  }
}

// rules to hide an element visually,
// but still make it focusable for a11y
@mixin visuallyHide {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}
@mixin visuallyShow {
  clip: unset;
  clip-path: unset;
  height: unset;
  overflow: unset;
  position: relative;
  white-space: unset;
}
